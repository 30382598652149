import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import FSAImage from '../../images/image_qsehra.jpg';

const Enrollment = () => (
  <ServicesLayout title="Qualified Small Employer Health Reimbursement Arrangement" bannerTitle="Qualified Small Employer Health Reimbursement Arrangement" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">Qualified Small Employer Health Reimbursement Arrangement (QSEHRA)</h2>
    <p className="inner-page__lead">Small employers can reimburse their employees for health insurance premiums and medical expenses, tax-free!</p>
    <img className="inner-page__hero" src={FSAImage} alt="Proud small business owner" />

    <p>QSEHRA allows small employers (fewer than 50 full-time-equivalent [FTE] employees) to set aside a fixed amount of money each month that employees can use to purchase individual health insurance or use on medical expenses, tax-free. This means employers get to offer benefits in a tax-efficient manner without the hassle or headache of administering a traditional group plan and employees can choose the plan they want.</p>

    <h3>How it works</h3>
    <p>The mechanics of a QSEHRA are surprisingly simple. At a high-level, employees pay for their own health expenses and you reimburse them. Here’s how it works:</p>
    <ul>
      <li>Employers design their plan and set reimbursement allowances</li>
      <li>Employees pay for their own health insurance and medical bills</li>
      <li>Employees provide proof of their expenses</li>
      <li>Employers reimburse the employee up to the set limit</li>
    </ul>
    <p>The key to note is payments are reimbursements. Employees will pay the insurance company or doctor’s office directly and then submit a claim to get reimbursed for their expenses tax-free.</p>

    <h3>Tri-Star Service Highlights:</h3>
    <ul>
      <li>For businesses not offering group coverage with fewer than 50 FTE employees</li>
      <li>Automated QSEHRA benefit compliance</li>
      <li>Reimburse health insurance premiums as well as out-of-pocket medical expenses</li>
      <li>Vary allowances by family status</li>
      <li>No minimum employer contribution requirements</li>
      <li>Automated QSEHRA employee reimbursement limit management</li>
    </ul>

    <h3>QSEHRA Features:</h3>
    <ul>
      <li>Online benefits portal for administrators and employees</li>
      <li>Automated benefit compliance documents</li>
      <li>Dedicated customer success manager for administrators</li>
      <li>Employee onboarding and education</li>
      <li>Employee access to team of personalized health benefits experts</li>
      <li>Expense review and verification</li>
      <li>HIPAA medical privacy law compliance</li>
      <li>Premium and expense verification within 2 business days</li>
      <li>Mobile access for user-friendly expense documentation upload</li>
    </ul>
  </ServicesLayout>
);

export default Enrollment
